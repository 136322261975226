import type { AxiosResponse } from 'axios';

import { gtmClient } from '@/services/gtm';

import { ApiClient } from '../../apiClient';

import type {
  FetchProjectListResponse,
  FetchProjectResponse,
  CreateProjectPayload,
  CreateProjectResponse,
  UpdateProjectPayload,
  UpdateProjectResponse,
  DeleteProjectPayload,
  DeleteProjectResponse,
  FetchProjectUsersResponse,
  AddProjectUserPayload,
  AddProjectUserResponse,
  DeleteProjectUserPayload,
  DeleteProjectUserResponse,
  FetchProjectConnectorUsersPayload,
  FetchProjectConnectorUsersResponse,
  SetFavoriteProjectResponse,
  SetFavoriteProjectPayload,
  FetchProjectListPayload,
  FetchProjectUserPayload,
  FetchProjectUserResponse,
} from './types';

export class ProjectApiClient extends ApiClient {
  async fetchProjectList(
    payload: FetchProjectListPayload,
  ): Promise<AxiosResponse<FetchProjectListResponse>> {
    const { spaceId } = payload;

    const res = await this.httpClient.get<FetchProjectListResponse>(
      `/api/${spaceId}/project/list`,
      { params: payload.params },
    );

    return res;
  }

  async fetchProject(payload: {
    spaceId: number;
    projectId: number;
  }): Promise<AxiosResponse<FetchProjectResponse>> {
    const { spaceId, projectId } = payload;

    const res = await this.httpClient.get<FetchProjectResponse>(
      `/api/${spaceId}/${projectId}`,
    );

    return res;
  }

  async createProject(
    payload: CreateProjectPayload,
  ): Promise<AxiosResponse<CreateProjectResponse>> {
    const { spaceId, data } = payload;

    const res = await this.httpClient.post<CreateProjectResponse>(
      `/api/${spaceId}/project/create`,
      data,
    );

    gtmClient.track({
      event: 'user_action',
      event_name: 'projects_add',
      ddh_space_id: spaceId,
      ddh_project_id: undefined,
    });

    return res;
  }

  async addToFavorite(
    payload: SetFavoriteProjectPayload,
  ): Promise<AxiosResponse<SetFavoriteProjectResponse>> {
    const { spaceId, projectId } = payload;

    const res = await this.httpClient.put<UpdateProjectResponse>(
      `/api/${spaceId}/${projectId}/favorite`,
    );

    return res;
  }

  async deleteFromFavorite(
    payload: SetFavoriteProjectPayload,
  ): Promise<AxiosResponse<SetFavoriteProjectResponse>> {
    const { spaceId, projectId } = payload;

    const res = await this.httpClient.delete<UpdateProjectResponse>(
      `/api/${spaceId}/${projectId}/favorite`,
    );

    return res;
  }

  async updateProject(
    payload: UpdateProjectPayload,
  ): Promise<AxiosResponse<UpdateProjectResponse>> {
    const { spaceId, projectId, data } = payload;

    const res = await this.httpClient.patch<UpdateProjectResponse>(
      `/api/${spaceId}/${projectId}`,
      data,
    );

    return res;
  }

  async deleteProject(
    payload: DeleteProjectPayload,
  ): Promise<AxiosResponse<DeleteProjectResponse>> {
    const { spaceId, projectId } = payload;

    const res = await this.httpClient.delete<DeleteProjectResponse>(
      `/api/${spaceId}/${projectId}`,
    );

    gtmClient.track({
      event: 'user_action',
      event_name: 'projects_delete',
      ddh_space_id: spaceId,
      ddh_project_id: projectId,
    });

    return res;
  }

  async fetchProjectUsers(payload: {
    spaceId: number;
    projectId: number;
    params?: {
      search?: string;
      limit?: number;
      offset?: number;
      order_by?: string;
    };
  }): Promise<AxiosResponse<FetchProjectUsersResponse>> {
    const { spaceId, projectId, params } = payload;

    const res = await this.httpClient.get<FetchProjectUsersResponse>(
      `/api/${spaceId}/${projectId}/users`,
      { params },
    );

    return res;
  }

  async fetchProjectUser(
    payload: FetchProjectUserPayload,
  ): Promise<AxiosResponse<FetchProjectUserResponse>> {
    const { spaceId, projectId, userId } = payload;

    const res = await this.httpClient.get<FetchProjectUserResponse>(
      `/api/${spaceId}/${projectId}/users/${userId}`,
    );

    return res;
  }

  async addProjectUser(
    payload: AddProjectUserPayload,
  ): Promise<AxiosResponse<AddProjectUserResponse>> {
    const { spaceId, projectId, data } = payload;

    const res = await this.httpClient.post<AddProjectUserResponse>(
      `/api/${spaceId}/${projectId}/users`,
      data,
    );

    gtmClient.track({
      event: 'user_action',
      event_name: 'user_add',
      ddh_space_id: spaceId,
      ddh_project_id: projectId,
    });

    return res;
  }

  async deleteProjectUser(
    payload: DeleteProjectUserPayload,
  ): Promise<AxiosResponse<DeleteProjectUserResponse>> {
    const { spaceId, projectId, userId } = payload;

    const res = await this.httpClient.delete<DeleteProjectUserResponse>(
      `/api/${spaceId}/${projectId}/users/${userId}`,
    );

    gtmClient.track({
      event: 'user_action',
      event_name: 'user_remove',
      ddh_space_id: spaceId,
      ddh_project_id: projectId,
    });

    return res;
  }

  async fetchProjectConnectorUsers(
    payload: FetchProjectConnectorUsersPayload,
  ): Promise<AxiosResponse<FetchProjectConnectorUsersResponse>> {
    const { spaceId, projectId } = payload;

    const res = await this.httpClient.get<FetchProjectConnectorUsersResponse>(
      `/api/${spaceId}/${projectId}/connector_users`,
    );

    return res;
  }
}

export const projectApiClient = new ProjectApiClient();

export default { ProjectApiClient, projectApiClient };
