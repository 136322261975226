export const shieldingSearchQuery = (query: string): string => {
  return query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const shieldingFilterValueQuery = (query: string): string => {
  return query.replace(/[%;,]/g, '\\$&');
};

export const unshieldedFilterValueQuery = (query: string): string => {
  return query.replace(/\\([%;,])/g, '$1');
};
