<template>
  <v-avatar
    v-if="image"
    :class="rootClassNames"
    :density="density"
    :color="color"
    :image="image"
    :rounded="rounded || 'circle'"
  ></v-avatar>
  <v-avatar
    v-else
    :class="rootClassNames"
    :density="density"
    :color="color"
    :rounded="rounded || 'circle'"
  >
    <slot />
  </v-avatar>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { VAvatar } from 'vuetify/components';

import { getColorGradient } from '@/utils';

import { useClassNames } from '../../composables';

export type DdhAvatarDensity = NonNullable<VAvatar['$props']['density']>;

const props = withDefaults(
  defineProps<{
    id?: string | number;
    color?: string;
    density?: DdhAvatarDensity;
    rounded?: boolean;
    image?: string | null;
  }>(),
  {
    id: '',
    color: '',
    density: 'default',
    rounded: false,
    image: null,
  },
);

const { rootClassNames } = useClassNames({
  name: 'ddh-avatar',
  classNames: (className) => {
    const densityClassNamesMap: Record<DdhAvatarDensity, string> = {
      default: `text-subtitle-1`,
      comfortable: `text-subtitle-1`,
      compact: `text-caption`,
    };

    const densityClassNames = [
      `${className}--${props.density}`,
      densityClassNamesMap[props.density],
    ];

    return [...densityClassNames];
  },
});

const color = computed(() => {
  const defaultColorGradient = 'blue-gradient';

  if (props.color) {
    return props.color;
  }

  if (props.id) {
    const colorGradient = getColorGradient(props.id);

    return colorGradient;
  }

  return defaultColorGradient;
});
</script>

<style lang="scss" scoped>
.ddh-avatar {
  &.v-avatar {
    &--rounded {
      border-radius: 6px;
    }
  }
}
</style>
