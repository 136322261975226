export default {
  base: 'Basic report',
  saveReport: 'Save report',
  saveReportAsNew: 'Save as new',
  saveComplete: 'The report was successfully saved',
  editName: 'Rename the report',
  editComplete: 'Report title changed',
  savingReport: 'Saving a report',
  notEmptyName: 'The name cannot be empty',
  name: 'Name',
  placeholder: 'My report',
  cancel: 'Cancel',
  save: 'Save',
  delete: 'Delete',
  deleteModal: {
    title: 'Delete report?',
    subtitle: 'Are you sure you want to delete report: "{name}"?',
  },
  saveSettingsReport: 'Save report settings?',
  saveSettingsInfo:
    'Create your reports by selecting filters and metrics, save them, and then quickly access the data by selecting the saved report.',
  magicLinkNotification:
    'You are looking at the original report for magic link: "{name}". If you change the report configuration, this will not affect the magic link settings.',
};
