import { computed, type Ref } from 'vue';
import { useQuery, type UseQueryReturnType } from '@tanstack/vue-query';

import {
  userApiClient,
  type RawSuggestUser,
  type FetchUserSuggestParams,
} from '@/services/api';

import { AppQueryKey } from '../../types';

export const useUserSuggestQuery = (
  payload: {
    params: Ref<FetchUserSuggestParams>;
  },
  options?: {
    keepPreviousData?: boolean | Ref<boolean>;
    enabled?: boolean | Ref<boolean>;
  },
): Omit<UseQueryReturnType<RawSuggestUser[], unknown>, 'data'> & {
  data: Ref<RawSuggestUser[]>;
} => {
  const { params } = payload;
  const { keepPreviousData, enabled } = { ...options };

  const query = useQuery({
    queryKey: [AppQueryKey.UserSuggest, { params }],
    queryFn: async () => {
      const res = await userApiClient.fetchUserSuggest({
        params: params.value,
      });

      return res.data.data;
    },
    keepPreviousData,
    enabled,
  });

  const { data, ...other } = query;

  return {
    data: computed(() => data.value || []),
    ...other,
  };
};

export default { useUserSuggestQuery };
