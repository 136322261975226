export default {
  selectors: 'Dimensions and metrics',
  cancel: 'Cancel',
  save: 'Save',
  error: 'It is not possible to build a report with the selected configuration',
  tooMany: 'Too many items',
  labels: {
    title: 'Labels',
    search: 'Find Labels',
    selected: 'Selected Labels',
    placeholder: `This is where your selected<br />your selected labels will be displayed, while this is empty`,
  },
  dimensions: {
    title: 'Dimensions',
    search: 'Find a dimensionality',
    selected: 'Selected Dimensions',
    placeholder: `The dimensions selected<br />by you will be displayed here, while this is empty`,
  },
  metrics: {
    title: 'Metrics',
    search: 'Find Metrics',
    selected: 'Selected Metrics',
    placeholder: `This is where your selected<br />your selected metrics will be displayed, while this is empty`,
  },
};
