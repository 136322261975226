export default {
  selectGroup: 'Выберите метрику/группировку',
  nameGroup: 'Назв. метрики/группировки',
  operations: {
    equal: 'Равно (=)',
    moreOrEqual: 'Больше или равно',
    lessOrEqual: 'Меньше или равно',
    startWith: 'Начинается с',
    include: 'Содержит',
  },
  active: 'Включить',
  noActive: 'Исключить',
  tagNoActive: 'Искл.',
  requiredField: 'Обязательное поле',
  ignoreCase: 'Не учитывать регистр',
  invalidCharacters: 'Недопустимые символы ("," , ";")',
  cantDuplicate: 'Невозможно выбрать два одинаковых условия',
  and: 'и',
  or: 'или',
};
