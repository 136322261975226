<template>
  <CvAutocomplete ref="rootRef" :class="rootClassNames" :required="required">
    <template v-for="(_, name) in $slots" #[name]="scope">
      <slot :name="name" v-bind="scope || {}" />
    </template>
  </CvAutocomplete>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

import type { VAutocomplete } from 'vuetify/components';

import { useClassNames } from '../../composables';

const props = withDefaults(
  defineProps<{
    required?: boolean;
    detailsPositionAbsolute?: boolean;
  }>(),
  {
    required: false,
    detailsPositionAbsolute: false,
  },
);

const rootRef = ref<VAutocomplete | null>(null);

const validate = () => rootRef.value?.validate();

const { rootClassNames } = useClassNames({
  name: 'ddh-autocomplete',
  classNames: (className) => {
    const requiredClassName = props.required ? `${className}--required` : '';

    const detailsPositionClassName = props.detailsPositionAbsolute
      ? `${className}--absolute`
      : '';

    return [requiredClassName, detailsPositionClassName];
  },
});

defineExpose({
  validate,
});
</script>

<style lang="scss" scoped>
.ddh-autocomplete {
  position: relative;

  &--required {
    :deep {
      &::after {
        color: var(--error-base);
        content: '*';
      }
    }
  }

  &--absolute {
    :deep {
      .v-input {
        &__details {
          position: absolute;
          bottom: 0;
          width: 100%;
          transform: translateY(100%);
        }
      }
    }
  }
}
</style>
