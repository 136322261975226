export default {
  selectors: 'Размерности и метрики',
  cancel: 'Отмена',
  save: 'Сохранить',
  error: 'Невозможно построить отчёт с выбранной конфигурацией',
  tooMany: 'Слишком много полей',
  labels: {
    title: 'Лейблы',
    search: 'Найти лейбл',
    selected: 'Выбранные лейблы',
    placeholder:
      'Здесь будут отображаться выбранные<br />вами лейблы, пока тут пусто',
  },
  dimensions: {
    title: 'Группировки',
    search: 'Найти группировку',
    selected: 'Выбранные группировки',
    placeholder:
      'Здесь будут отображаться выбранные<br />вами группировки, пока тут пусто',
  },
  metrics: {
    title: 'Метрики',
    search: 'Найти метрику',
    selected: 'Выбранные метрики',
    placeholder:
      'Здесь будут отображаться выбранные<br />вами метрики, пока тут пусто',
  },
};
