<template>
  <v-menu
    v-model="isShowSwitcher"
    :close-on-content-click="false"
    class="app-header-user"
  >
    <template #default>
      <v-form
        ref="vFormRef"
        class="app-header-user__form elevation-10"
        validate-on="submit"
        @submit.prevent="onSubmit"
      >
        <template v-if="userMe?.grants.switch && !userMe.original_user">
          <DdhAutocomplete
            :model-value="switchedAccount"
            :search="searchUser"
            class="app-header-user__text-field"
            placeholder="Имя или email пользователя"
            :loading="isLoadingUsers && searchUser.length > 0"
            :rules="[rules.required, rules.isValid]"
            :items="availableAccounts"
            item-title="name"
            menu-icon=""
            :hide-no-data="
              searchUser.length === 0 ||
              (switchedAccount && searchUser === switchedAccount.name)
            "
            no-filter
            hide-selected
            autofocus
            details-position-absolute
            @update:search="updateSearch"
          >
            <template #item="{ item }">
              <CvListItem @click="updateSwitchedAccount(item.raw)">
                <template #prepend>
                  <DdhAvatar :id="item.raw.id" size="20" density="compact">{{
                    getAbbreviation([item.raw.given_name, item.raw.family_name])
                  }}</DdhAvatar>
                </template>
                <template #default="{ isActive }">
                  <CvListItemTitle :class="{ 'text-primary': isActive }">
                    <DdhHighlightText
                      :text="item.raw.name"
                      :query="searchUser"
                    />
                  </CvListItemTitle>
                  <CvListItemSubtitle :class="{ 'text-primary': isActive }">
                    <DdhHighlightText
                      :text="item.raw.email"
                      :query="searchUser"
                    />
                  </CvListItemSubtitle>
                </template>
              </CvListItem>
            </template>
            <template #prepend-inner>
              <v-icon v-if="!switchedAccount" size="20" color="grey-darken-1">
                $ddh-user-01
              </v-icon>
              <DdhAvatar
                v-else
                :id="switchedAccount.id"
                size="20"
                density="compact"
              >
                {{
                  getAbbreviation([
                    switchedAccount.given_name,
                    switchedAccount.family_name,
                  ])
                }}</DdhAvatar
              >
            </template>
            <template #no-data>
              <div
                v-if="isLoadingUsers"
                class="flex-grow-1 mt-5 mb-5 d-flex flex-column justify-center align-center"
              >
                <v-progress-circular color="primary" indeterminate />
              </div>
              <AppDataStub
                v-else
                class="flex-grow-1 h-100 mt-5 mb-5"
                title="Ничего не найдено"
                text="Ваш запрос не дал результатов,<br/ >попробуйте другие ключевые слова"
                :src="imageDataEmptySearchSvg"
              />
            </template>
          </DdhAutocomplete>
          <CvBtn
            type="submit"
            :loading="isLoading"
            class="mt-4"
            style="width: 100%"
            >Войти под пользователем</CvBtn
          >
        </template>
        <CvBtn
          v-if="userMe?.original_user"
          :loading="isLoading"
          style="width: 100%"
          @click="logoutFromSwitchedUser"
          >Выйти из просмотра от имени пользователя</CvBtn
        >
        <CvBtn
          v-else
          class="mt-2"
          color="grey-lighten-3"
          :border="false"
          variant="outlined"
          style="width: 100%"
          @click="goToLogout"
        >
          <span class="text-black">Выйти</span>
        </CvBtn>
      </v-form>
    </template>
    <template #activator="{ props, isActive }">
      <v-btn
        class="app-header-user__user-menu-button bg-grey-lighten-4"
        density="comfortable"
        variant="plain"
        color="grey-lighten-4"
        v-bind="props"
      >
        <template #default>
          <DdhAvatar :id="userMe?.id" density="comfortable">{{
            userMeInitials
          }}</DdhAvatar>
          <v-icon
            size="24"
            color="grey-darken-1"
            :icon="`mdi-menu-${isActive ? 'up' : 'down'}`"
          />
        </template>
      </v-btn>
    </template>
  </v-menu>
</template>
<script setup lang="ts">
import {
  DdhAutocomplete,
  DdhAvatar,
  DdhHighlightText,
} from '@/packages/ddh-ui-kit';
import type { VForm, VMenu } from 'vuetify/components';
import {
  useUserMe,
  useUserSuggestQuery,
  useSwitchAccountMutation,
  useLogoutMutation,
} from '@/composables';
import { computed, ref, watch } from 'vue';
import { getAbbreviation } from '@/utils';
import { httpClientErrorHandler, type RawSuggestUser } from '@/services/api';
import { useNotify } from '@/packages/notify';
import imageDataEmptySearchSvg from '@/assets/images/data-empty-search.svg';
import AppDataStub from '@/components/AppDataStub.vue';
import router, { RouteName } from '@/router';

const { userMe, userMeInitials } = useUserMe();

const isShowSwitcher = ref(false);
const searchUser = ref('');
const switchedAccount = ref<RawSuggestUser | null>(null);

const updateSwitchedAccount = (user: RawSuggestUser) => {
  switchedAccount.value = user;
  searchUser.value = user.name;
};

const { mutateAsync: switchAccount } = useSwitchAccountMutation();

const updateSearch = (search: string) => {
  searchUser.value = search;
};

const { data: availableAccounts, isLoading: isLoadingUsers } =
  useUserSuggestQuery(
    {
      params: computed(() => ({ search: searchUser.value })),
    },
    {
      enabled: computed(() => !!searchUser.value.length),
    },
  );

const { mutateAsync: logoutUser } = useLogoutMutation({
  onSuccess: () => {
    router.push({
      name: RouteName.Logout,
    });
  },
});

const isExistEmail = ref(true);
const isLoading = ref(false);

const rules = {
  required: (value: string) => !!value || 'Обязательное поле',
  isValid: async () => {
    return (
      isExistEmail.value ||
      'Что-то пошло не так, возможно этот пользователь еще не зарегистрирован'
    );
  },
};

const vFormRef = ref<VForm | null>(null);
const notify = useNotify();

const switchError = () =>
  notify.push({
    status: 'error',
    title: 'Некорректная почта',
    content: 'Проверьте правильность почты и повторите попытку',
    elevated: true,
    timeout: 9000,
  });

const logoutError = () =>
  notify.push({
    status: 'error',
    title: 'Некорректная почта',
    content: 'Проверьте правильность почты и повторите попытку',
    elevated: true,
    timeout: 9000,
  });

const goToLogout = async () => {
  try {
    await logoutUser();
  } catch (e) {
    logoutError();
  }
};

const onSubmit = async () => {
  if (!vFormRef.value || !switchedAccount.value) return;

  try {
    await switchAccount({ _switch_user: switchedAccount.value.email });
    window.location.reload();
  } catch (e) {
    httpClientErrorHandler({
      error: e,
      callback: switchError,
    });
  } finally {
    isLoading.value = false;
  }
};

watch(searchUser, () => {
  isExistEmail.value = true;
});

const logoutFromSwitchedUser = async () => {
  isLoading.value = true;

  try {
    await switchAccount({ _switch_user: '_exit' });
    window.location.reload();
  } catch (e) {
    httpClientErrorHandler({
      error: e,
      callback: switchError,
    });
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped lang="scss">
.app-header-user {
  &__form {
    width: fit-content;
    min-width: 290px;
    padding: 16px;
    margin-top: 4px;
    background-color: var(--shades-white);
    border: 1px solid var(--grey-lighten-4);
    border-radius: 12px;
  }

  &__user-menu-button {
    width: 60px;
    height: 36px;
    padding: 2px;
    border-radius: 28px;
    opacity: 1;

    &::before {
      background-color: transparent;
    }
  }

  &__text-field {
    width: 260px;
  }
}
</style>
