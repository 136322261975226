export enum UserRoleKey {
  Admin = 'ADMINISTRATOR',
  Manager = 'MANAGER',
  User = 'USER',
}

export enum UserTypeKey {
  SuperUser = 'super_user',
  General = 'general',
}

export enum UserAuthMethodType {
  Json = 'json',
  Redirect = 'redirect',
}

export interface RawUserMe {
  id: number;
  given_name: string;
  family_name: string;
  email: string;
  type: UserTypeKey;
  original_user?: RawUserMe;
  grants: {
    create_spaces: boolean;
    switch: boolean;
  };
}

export interface RawUserAuthMethodConfigBase {
  type: UserAuthMethodType;
}

export interface RawUserAuthMethodRedirectConfig
  extends RawUserAuthMethodConfigBase {
  type: UserAuthMethodType.Redirect;
  redirect: {
    url: string;
    name: string;
  };
}

export interface RawUserAuthMethodJsonConfig
  extends RawUserAuthMethodConfigBase {
  type: UserAuthMethodType.Json;
  endpoint: string;
  email_mapping: string;
  fields: {
    _user_login: null;
    _user_password: string;
  };
}

export type RawUserAuthMethodConfig =
  | RawUserAuthMethodRedirectConfig
  | RawUserAuthMethodJsonConfig;

export interface RawUserAuthMethodInfo {
  method: unknown;
  config: RawUserAuthMethodConfig;
}

export interface RawSuggestUser {
  id: number;
  name: string;
  given_name: string;
  family_name: string;
  email: string;
  last_login_at: number;
}

export interface FetchUserMeResponse {
  ok: boolean;
  data: RawUserMe;
}

export interface FetchUserAuthMethodResponse {
  ok: boolean;
  data: RawUserAuthMethodInfo;
}

export interface FetchUserAuthMethodParams {
  email: string;
}

export interface FetchUserAuthMethodPayload {
  params: FetchUserAuthMethodParams;
}

export interface FetchUserSuggestParams {
  search: string;
}

export interface FetchUserSuggestPayload {
  params: FetchUserSuggestParams;
}

export interface FetchUserSuggestResponse {
  ok: boolean;
  data: RawSuggestUser[];
}

export interface SwitchUserPayload {
  _switch_user: string;
}

export interface SwitchUserResponse {
  ok: boolean;
}
export interface UserLogoutResponse {
  ok: boolean;
  data: {
    message: string;
  };
}
