import { AdsConnectorType } from './adsConnectors';

export enum FileFormatKey {
  Csv = 'csv',
  Tsv = 'tsv',
  Json = 'json',
}
export enum ValueType {
  Boolean = 'bool',
  String = 'string',
  Integer = 'integer',
  Numeric = 'numeric',
  Date = 'date',
  Datetime = 'datetime',
}
export enum ConnectorShortKey {
  AdRiver = 'adriver',
  MyTarget = 'mt',
  VK = 'vk',
  VKAds = 'vk_ads',
  YandexDirect = 'yd',
  YandexMetrika = 'ym',
  Weborama = 'wor',
  Adserving = 'adserving',
  Hybrid = 'hybrid',
  MtsDsp = 'mts_dsp',
  DigitalAlliance = 'digital_alliance',
}

export enum ReportKey {
  AdRiver = ConnectorShortKey.AdRiver,
  MyTarget = ConnectorShortKey.MyTarget,
  VK = ConnectorShortKey.VK,
  VKAds = ConnectorShortKey.VKAds,
  YandexDirect = ConnectorShortKey.YandexDirect,
  YandexMetrika = ConnectorShortKey.YandexMetrika,
  Weborama = ConnectorShortKey.Weborama,
  Adserving = ConnectorShortKey.Adserving,
  Hybrid = ConnectorShortKey.Hybrid,
  MtsDsp = ConnectorShortKey.MtsDsp,
  DigitalAlliance = ConnectorShortKey.DigitalAlliance,
}

export enum ReportSourceKey {
  Table = 'table',
  Chart = 'chart',
}

export const adsConnectorTypeToShortKeyMap: Record<
  AdsConnectorType,
  ConnectorShortKey
> = {
  [AdsConnectorType.Mytarget]: ConnectorShortKey.MyTarget,
  [AdsConnectorType.Vk]: ConnectorShortKey.VK,
  [AdsConnectorType.YandexDirect]: ConnectorShortKey.YandexDirect,
  [AdsConnectorType.YandexMetrika]: ConnectorShortKey.YandexMetrika,
  [AdsConnectorType.VkAds]: ConnectorShortKey.VKAds,
  [AdsConnectorType.Weborama]: ConnectorShortKey.Weborama,
  [AdsConnectorType.Adriver]: ConnectorShortKey.AdRiver,
  [AdsConnectorType.Adserving]: ConnectorShortKey.Adserving,
  [AdsConnectorType.Hybrid]: ConnectorShortKey.Hybrid,
  [AdsConnectorType.MtsDsp]: ConnectorShortKey.MtsDsp,
  [AdsConnectorType.DigitalAlliance]: ConnectorShortKey.DigitalAlliance,
};

export const adsConnectorTypeToReportKeyMap: Record<
  AdsConnectorType,
  ReportKey
> = {
  [AdsConnectorType.Mytarget]: ReportKey.MyTarget,
  [AdsConnectorType.Vk]: ReportKey.VK,
  [AdsConnectorType.YandexDirect]: ReportKey.YandexDirect,
  [AdsConnectorType.YandexMetrika]: ReportKey.YandexMetrika,
  [AdsConnectorType.VkAds]: ReportKey.VKAds,
  [AdsConnectorType.Weborama]: ReportKey.Weborama,
  [AdsConnectorType.Adriver]: ReportKey.AdRiver,
  [AdsConnectorType.Adserving]: ReportKey.Adserving,
  [AdsConnectorType.Hybrid]: ReportKey.Hybrid,
  [AdsConnectorType.MtsDsp]: ReportKey.MtsDsp,
  [AdsConnectorType.DigitalAlliance]: ReportKey.DigitalAlliance,
};

export const adsReportKeyToConnectorTypeMap: Record<
  ReportKey,
  AdsConnectorType
> = {
  [ReportKey.MyTarget]: AdsConnectorType.Mytarget,
  [ReportKey.VK]: AdsConnectorType.Vk,
  [ReportKey.YandexDirect]: AdsConnectorType.YandexDirect,
  [ReportKey.YandexMetrika]: AdsConnectorType.YandexMetrika,
  [ReportKey.VKAds]: AdsConnectorType.VkAds,
  [ReportKey.Weborama]: AdsConnectorType.Weborama,
  [ReportKey.AdRiver]: AdsConnectorType.Adriver,
  [ReportKey.Adserving]: AdsConnectorType.Adserving,
  [ReportKey.Hybrid]: AdsConnectorType.Hybrid,
  [ReportKey.MtsDsp]: AdsConnectorType.MtsDsp,
  [ReportKey.DigitalAlliance]: AdsConnectorType.DigitalAlliance,
};

export interface RawHighLevelObject {
  id: string;
  external_id: number;
  name: string;
  is_active: boolean;
}

export default {
  FileFormatKey,
  ConnectorShortKey,
  ValueType,
};
