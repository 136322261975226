import type { AxiosResponse } from 'axios';

import { gtmClient } from '@/services/gtm';

import { ApiClient } from '../../apiClient';

import type {
  FetchSpacePayload,
  FetchSpaceResponse,
  CreateSpacePayload,
  CreateSpaceResponse,
  FetchSpaceListResponse,
  FetchSpaceUsersPayload,
  FetchSpaceUsersResponse,
  AddSpaceUserToAdminsPayload,
  AddSpaceUserToAdminsResponse,
  AddSpaceUserToAllProjectsPayload,
  AddSpaceUserToAllProjectsResponse,
  AddSpaceUserToProjectsPayload,
  AddSpaceUserToProjectsResponse,
  DeleteSpaceUserFromAdminsPayload,
  DeleteSpaceUserFromAdminsResponse,
  DeleteSpaceUserPayload,
  DeleteSpaceUserResponse,
  JoinSpaceAdsAccountPayload,
  JoinSpaceAdsAccountResponse,
  FetchSpaceUserResponse,
  FetchSpaceUserPayload,
} from './types';

export class SpaceApiClient extends ApiClient {
  async fetchSpace(
    payload: FetchSpacePayload,
  ): Promise<AxiosResponse<FetchSpaceResponse>> {
    const { spaceId } = payload;

    const res = await this.httpClient.get<FetchSpaceResponse>(
      `/api/space/${spaceId}`,
    );

    return res;
  }

  async createSpace(
    payload: CreateSpacePayload,
  ): Promise<AxiosResponse<CreateSpaceResponse>> {
    const { data } = payload;

    const res = await this.httpClient.post<CreateSpaceResponse>(
      '/api/space/create',
      data,
    );

    return res;
  }

  async fetchSpaceList(): Promise<AxiosResponse<FetchSpaceListResponse>> {
    const res = await this.httpClient.get<FetchSpaceListResponse>(
      '/api/space/list',
    );

    return res;
  }

  async fetchSpacesUsers(
    payload: FetchSpaceUsersPayload,
  ): Promise<AxiosResponse<FetchSpaceUsersResponse>> {
    const { spaceId, params } = payload;

    const res = await this.httpClient.get<FetchSpaceUsersResponse>(
      `/api/${spaceId}/users`,
      { params },
    );

    return res;
  }

  async addSpaceUserToAdmins(
    payload: AddSpaceUserToAdminsPayload,
  ): Promise<AxiosResponse<AddSpaceUserToAdminsResponse>> {
    const { spaceId, data } = payload;

    const res = await this.httpClient.post<AddSpaceUserToAdminsResponse>(
      `/api/${spaceId}/administrators`,
      data,
    );

    return res;
  }

  async addSpaceUserToAllProjects(
    payload: AddSpaceUserToAllProjectsPayload,
  ): Promise<AxiosResponse<AddSpaceUserToAllProjectsResponse>> {
    const { spaceId, data } = payload;

    const res = await this.httpClient.post<AddSpaceUserToAllProjectsResponse>(
      `/api/${spaceId}/users`,
      data,
    );

    gtmClient.track({
      event: 'user_action',
      event_name: 'user_add',
      ddh_space_id: spaceId,
      ddh_project_id: undefined,
    });

    return res;
  }

  async addSpaceUserToProjects(
    payload: AddSpaceUserToProjectsPayload,
  ): Promise<AxiosResponse<AddSpaceUserToProjectsResponse>> {
    const { spaceId, data } = payload;

    const res = await this.httpClient.put<AddSpaceUserToProjectsResponse>(
      `/api/${spaceId}/users`,
      data,
    );

    gtmClient.track({
      event: 'user_action',
      event_name: 'user_add',
      ddh_space_id: spaceId,
      ddh_project_id: undefined,
    });

    return res;
  }

  async deleteSpaceUserFromAdmins(
    payload: DeleteSpaceUserFromAdminsPayload,
  ): Promise<AxiosResponse<DeleteSpaceUserFromAdminsResponse>> {
    const { spaceId, userId } = payload;

    const res = await this.httpClient.delete<DeleteSpaceUserFromAdminsResponse>(
      `/api/${spaceId}/administrators/${userId}`,
    );

    return res;
  }

  async fetchSpaceUser(
    payload: FetchSpaceUserPayload,
  ): Promise<AxiosResponse<FetchSpaceUserResponse>> {
    const { spaceId, userId } = payload;

    const res = await this.httpClient.get<FetchSpaceUserResponse>(
      `/api/${spaceId}/users/${userId}`,
    );

    return res;
  }

  async deleteSpaceUser(
    payload: DeleteSpaceUserPayload,
  ): Promise<AxiosResponse<DeleteSpaceUserResponse>> {
    const { spaceId, userId } = payload;

    const res = await this.httpClient.delete<DeleteSpaceUserResponse>(
      `/api/${spaceId}/users/${userId}`,
    );

    gtmClient.track({
      event: 'user_action',
      event_name: 'user_remove',
      ddh_space_id: spaceId,
      ddh_project_id: undefined,
    });

    return res;
  }

  async joinSpaceAdsAccount(
    payload: JoinSpaceAdsAccountPayload,
  ): Promise<AxiosResponse<JoinSpaceAdsAccountResponse>> {
    const { spaceId, adsConnectorType, data } = payload;

    const res = await this.httpClient.post<JoinSpaceAdsAccountResponse>(
      `/api/${spaceId}/connector_users/${adsConnectorType}/connect`,
      data,
    );

    return res;
  }
}

export const spaceApiClient = new SpaceApiClient();

export default { SpaceApiClient, spaceApiClient };
