import type { ValueType } from '@/services/api';

export enum AdsConnectorAuthMethodType {
  Oauth = 'oauth',
  Form = 'form',
}
export enum AdsConnectorType {
  Mytarget = 'mytarget',
  Vk = 'vk',
  YandexDirect = 'yandex_direct',
  YandexMetrika = 'yandex_metrika',
  VkAds = 'vk_ads',
  Weborama = 'weborama',
  Adriver = 'adriver',
  Adserving = 'adserving',
  Hybrid = 'hybrid',
  MtsDsp = 'mts_dsp',
  DigitalAlliance = 'digital_alliance',
}
export type RawAdsConnectorAuthField = {
  is_required?: boolean;
  is_secure?: boolean;
  label: string;
  name: string;
  value_type: ValueType;
};
export type RawAdsConnectorAuthMethod = {
  type: AdsConnectorAuthMethodType;
  url: string;
  fields?: RawAdsConnectorAuthField[];
};
export interface RawAdsConnector {
  type: AdsConnectorType;
  name: string;
  auth_method: RawAdsConnectorAuthMethod;
}

// // //

export interface FetchAdsConnectorsPayload {
  spaceId: number;
}

export interface FetchAdsConnectorsResponse {
  ok: boolean;
  data: RawAdsConnector[];
}
