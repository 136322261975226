import { useMutation, type UseMutationReturnType } from '@tanstack/vue-query';

import { userApiClient } from '@/services/api';

export type UseSwitchAccountMutation = {
  _switch_user: string;
};

export const useSwitchAccountMutation = (): UseMutationReturnType<
  unknown,
  unknown,
  UseSwitchAccountMutation,
  unknown
> => {
  return useMutation({
    mutationFn: async (variables: UseSwitchAccountMutation) => {
      const res = await userApiClient.switchUser(variables);

      return res.data.ok;
    },
  });
};

export default { useSwitchAccountMutation };
