export const getAbbreviation = (list: (string | number)[]): string => {
  const abbr = list
    .map((item) => `${item}`[0] || '')
    .join('')
    .toUpperCase();

  return abbr;
};

export default { getAbbreviation };
