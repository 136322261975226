import { useMutation, type UseMutationReturnType } from '@tanstack/vue-query';
import { userApiClient } from '@/services/api';

export const useLogoutMutation = (options?: {
  onSuccess: () => void;
}): UseMutationReturnType<boolean, unknown, void, unknown> => {
  return useMutation({
    mutationFn: async () => {
      const res = await userApiClient.logoutUser();

      return res.data.ok;
    },
    ...options,
  });
};
export default { useLogoutMutation };
